.currency-flag {background-image: url(//junoactive.com/cdn/shop/t/55/assets/country-flags-40.png);background-repeat: no-repeat;background-position: top left;position: relative;display: inline-block;vertical-align: middle;width: 40px;height: 40px;overflow: hidden;box-shadow: 0 0 1px 0 rgba(0,0,0,0.3) inset;border-radius: 50%;background-clip: padding-box;}
.currency-flag.currency-flag--ad {background-position: 0 0;}
.currency-flag.currency-flag--ae {background-position: 0 -50px;}
.currency-flag.currency-flag--af {background-position: 0 -100px;}
.currency-flag.currency-flag--ag {background-position: 0 -150px;}
.currency-flag.currency-flag--ai {background-position: 0 -200px;}
.currency-flag.currency-flag--al {background-position: 0 -250px;}
.currency-flag.currency-flag--am {background-position: 0 -300px;}
.currency-flag.currency-flag--ao {background-position: 0 -350px;}
.currency-flag.currency-flag--ar {background-position: 0 -400px;}
.currency-flag.currency-flag--as {background-position: 0 -450px;}
.currency-flag.currency-flag--at {background-position: 0 -500px;}
.currency-flag.currency-flag--au {background-position: 0 -550px;}
.currency-flag.currency-flag--aw {background-position: 0 -600px;}
.currency-flag.currency-flag--ax {background-position: 0 -650px;}
.currency-flag.currency-flag--az {background-position: 0 -700px;}
.currency-flag.currency-flag--ba {background-position: 0 -750px;}
.currency-flag.currency-flag--bb {background-position: 0 -800px;}
.currency-flag.currency-flag--bd {background-position: 0 -850px;}
.currency-flag.currency-flag--be {background-position: 0 -900px;}
.currency-flag.currency-flag--bf {background-position: 0 -950px;}
.currency-flag.currency-flag--bg {background-position: 0 -1000px;}
.currency-flag.currency-flag--bh {background-position: 0 -1050px;}
.currency-flag.currency-flag--bi {background-position: 0 -1100px;}
.currency-flag.currency-flag--bj {background-position: 0 -1150px;}
.currency-flag.currency-flag--bl {background-position: 0 -1200px;}
.currency-flag.currency-flag--bm {background-position: 0 -1250px;}
.currency-flag.currency-flag--bn {background-position: 0 -1300px;}
.currency-flag.currency-flag--bo {background-position: 0 -1350px;}
.currency-flag.currency-flag--bq-bo {background-position: 0 -1400px;}
.currency-flag.currency-flag--bq-sa {background-position: 0 -1450px;}
.currency-flag.currency-flag--bq-se {background-position: 0 -1500px;}
.currency-flag.currency-flag--br {background-position: 0 -1550px;}
.currency-flag.currency-flag--bs {background-position: 0 -1600px;}
.currency-flag.currency-flag--bt {background-position: 0 -1650px;}
.currency-flag.currency-flag--bw {background-position: 0 -1700px;}
.currency-flag.currency-flag--by {background-position: 0 -1750px;}
.currency-flag.currency-flag--bz {background-position: 0 -1800px;}
.currency-flag.currency-flag--ca-bc {background-position: 0 -1850px;}
.currency-flag.currency-flag--ca {background-position: 0 -1900px;}
.currency-flag.currency-flag--cc {background-position: 0 -1950px;}
.currency-flag.currency-flag--cd {background-position: 0 -2000px;}
.currency-flag.currency-flag--cf {background-position: 0 -2050px;}
.currency-flag.currency-flag--cg {background-position: 0 -2100px;}
.currency-flag.currency-flag--ch {background-position: 0 -2150px;}
.currency-flag.currency-flag--ci {background-position: 0 -2200px;}
.currency-flag.currency-flag--ck {background-position: 0 -2250px;}
.currency-flag.currency-flag--cl {background-position: 0 -2300px;}
.currency-flag.currency-flag--cm {background-position: 0 -2350px;}
.currency-flag.currency-flag--cn {background-position: 0 -2400px;}
.currency-flag.currency-flag--co {background-position: 0 -2450px;}
.currency-flag.currency-flag--cr {background-position: 0 -2500px;}
.currency-flag.currency-flag--cu {background-position: 0 -2550px;}
.currency-flag.currency-flag--cv {background-position: 0 -2600px;}
.currency-flag.currency-flag--cw {background-position: 0 -2650px;}
.currency-flag.currency-flag--cx {background-position: 0 -2700px;}
.currency-flag.currency-flag--cy {background-position: 0 -2750px;}
.currency-flag.currency-flag--cz {background-position: 0 -2800px;}
.currency-flag.currency-flag--de {background-position: 0 -2850px;}
.currency-flag.currency-flag--dj {background-position: 0 -2900px;}
.currency-flag.currency-flag--dk {background-position: 0 -2950px;}
.currency-flag.currency-flag--dm {background-position: 0 -3000px;}
.currency-flag.currency-flag--do {background-position: 0 -3050px;}
.currency-flag.currency-flag--dz {background-position: 0 -3100px;}
.currency-flag.currency-flag--easter_island {background-position: 0 -3150px;}
.currency-flag.currency-flag--ec-w {background-position: 0 -3200px;}
.currency-flag.currency-flag--ec {background-position: 0 -3250px;}
.currency-flag.currency-flag--ee {background-position: 0 -3300px;}
.currency-flag.currency-flag--eg {background-position: 0 -3350px;}
.currency-flag.currency-flag--eh {background-position: 0 -3400px;}
.currency-flag.currency-flag--er {background-position: 0 -3450px;}
.currency-flag.currency-flag--es-ce {background-position: 0 -3500px;}
.currency-flag.currency-flag--es-cn {background-position: 0 -3550px;}
.currency-flag.currency-flag--es-ga {background-position: 0 -3600px;}
.currency-flag.currency-flag--es-ib {background-position: 0 -3650px;}
.currency-flag.currency-flag--es-ml {background-position: 0 -3700px;}
.currency-flag.currency-flag--es-pv {background-position: 0 -3750px;}
.currency-flag.currency-flag--es {background-position: 0 -3800px;}
.currency-flag.currency-flag--esperanto {background-position: 0 -3850px;}
.currency-flag.currency-flag--et {background-position: 0 -3900px;}
.currency-flag.currency-flag--european_union {background-position: 0 -3950px;}
.currency-flag.currency-flag--fi {background-position: 0 -4000px;}
.currency-flag.currency-flag--fj {background-position: 0 -4050px;}
.currency-flag.currency-flag--fk {background-position: 0 -4100px;}
.currency-flag.currency-flag--fm {background-position: 0 -4150px;}
.currency-flag.currency-flag--fo {background-position: 0 -4200px;}
.currency-flag.currency-flag--fr-h {background-position: 0 -4250px;}
.currency-flag.currency-flag--fr {background-position: 0 -4300px;}
.currency-flag.currency-flag--ga {background-position: 0 -4350px;}
.currency-flag.currency-flag--gb-eng {background-position: 0 -4400px;}
.currency-flag.currency-flag--gb-ork {background-position: 0 -4450px;}
.currency-flag.currency-flag--gb-sct {background-position: 0 -4500px;}
.currency-flag.currency-flag--gb-wls {background-position: 0 -4550px;}
.currency-flag.currency-flag--gb {background-position: 0 -4600px;}
.currency-flag.currency-flag--gd {background-position: 0 -4650px;}
.currency-flag.currency-flag--ge-ab {background-position: 0 -4700px;}
.currency-flag.currency-flag--ge {background-position: 0 -4750px;}
.currency-flag.currency-flag--gg {background-position: 0 -4800px;}
.currency-flag.currency-flag--gh {background-position: 0 -4850px;}
.currency-flag.currency-flag--gi {background-position: 0 -4900px;}
.currency-flag.currency-flag--gl {background-position: 0 -4950px;}
.currency-flag.currency-flag--gm {background-position: 0 -5000px;}
.currency-flag.currency-flag--gn {background-position: 0 -5050px;}
.currency-flag.currency-flag--gp {background-position: 0 -5100px;}
.currency-flag.currency-flag--gq {background-position: 0 -5150px;}
.currency-flag.currency-flag--gr {background-position: 0 -5200px;}
.currency-flag.currency-flag--gt {background-position: 0 -5250px;}
.currency-flag.currency-flag--gu {background-position: 0 -5300px;}
.currency-flag.currency-flag--gw {background-position: 0 -5350px;}
.currency-flag.currency-flag--gy {background-position: 0 -5400px;}
.currency-flag.currency-flag--hausa {background-position: 0 -5450px;}
.currency-flag.currency-flag--hk {background-position: 0 -5500px;}
.currency-flag.currency-flag--hmong {background-position: 0 -5550px;}
.currency-flag.currency-flag--hn {background-position: 0 -5600px;}
.currency-flag.currency-flag--hr {background-position: 0 -5650px;}
.currency-flag.currency-flag--ht {background-position: 0 -5700px;}
.currency-flag.currency-flag--hu {background-position: 0 -5750px;}
.currency-flag.currency-flag--id {background-position: 0 -5800px;}
.currency-flag.currency-flag--ie {background-position: 0 -5850px;}
.currency-flag.currency-flag--il {background-position: 0 -5900px;}
.currency-flag.currency-flag--im {background-position: 0 -5950px;}
.currency-flag.currency-flag--in {background-position: 0 -6000px;}
.currency-flag.currency-flag--io {background-position: 0 -6050px;}
.currency-flag.currency-flag--iq {background-position: 0 -6100px;}
.currency-flag.currency-flag--ir {background-position: 0 -6150px;}
.currency-flag.currency-flag--is {background-position: 0 -6200px;}
.currency-flag.currency-flag--it-82 {background-position: 0 -6250px;}
.currency-flag.currency-flag--it-88 {background-position: 0 -6300px;}
.currency-flag.currency-flag--it {background-position: 0 -6350px;}
.currency-flag.currency-flag--je {background-position: 0 -6400px;}
.currency-flag.currency-flag--jm {background-position: 0 -6450px;}
.currency-flag.currency-flag--jo {background-position: 0 -6500px;}
.currency-flag.currency-flag--jp {background-position: 0 -6550px;}
.currency-flag.currency-flag--kannada {background-position: 0 -6600px;}
.currency-flag.currency-flag--ke {background-position: 0 -6650px;}
.currency-flag.currency-flag--kg {background-position: 0 -6700px;}
.currency-flag.currency-flag--kh {background-position: 0 -6750px;}
.currency-flag.currency-flag--ki {background-position: 0 -6800px;}
.currency-flag.currency-flag--km {background-position: 0 -6850px;}
.currency-flag.currency-flag--kn {background-position: 0 -6900px;}
.currency-flag.currency-flag--kp {background-position: 0 -6950px;}
.currency-flag.currency-flag--kr {background-position: 0 -7000px;}
.currency-flag.currency-flag--kurdistan {background-position: 0 -7050px;}
.currency-flag.currency-flag--kw {background-position: 0 -7100px;}
.currency-flag.currency-flag--ky {background-position: 0 -7150px;}
.currency-flag.currency-flag--kz {background-position: 0 -7200px;}
.currency-flag.currency-flag--la {background-position: 0 -7250px;}
.currency-flag.currency-flag--lb {background-position: 0 -7300px;}
.currency-flag.currency-flag--lc {background-position: 0 -7350px;}
.currency-flag.currency-flag--li {background-position: 0 -7400px;}
.currency-flag.currency-flag--lk {background-position: 0 -7450px;}
.currency-flag.currency-flag--lr {background-position: 0 -7500px;}
.currency-flag.currency-flag--ls {background-position: 0 -7550px;}
.currency-flag.currency-flag--lt {background-position: 0 -7600px;}
.currency-flag.currency-flag--lu {background-position: 0 -7650px;}
.currency-flag.currency-flag--lv {background-position: 0 -7700px;}
.currency-flag.currency-flag--ly {background-position: 0 -7750px;}
.currency-flag.currency-flag--ma {background-position: 0 -7800px;}
.currency-flag.currency-flag--malayali {background-position: 0 -7850px;}
.currency-flag.currency-flag--maori {background-position: 0 -7900px;}
.currency-flag.currency-flag--mc {background-position: 0 -7950px;}
.currency-flag.currency-flag--md {background-position: 0 -8000px;}
.currency-flag.currency-flag--me {background-position: 0 -8050px;}
.currency-flag.currency-flag--mf {background-position: 0 -8100px;}
.currency-flag.currency-flag--mg {background-position: 0 -8150px;}
.currency-flag.currency-flag--mh {background-position: 0 -8200px;}
.currency-flag.currency-flag--mk {background-position: 0 -8250px;}
.currency-flag.currency-flag--ml {background-position: 0 -8300px;}
.currency-flag.currency-flag--mm {background-position: 0 -8350px;}
.currency-flag.currency-flag--mn {background-position: 0 -8400px;}
.currency-flag.currency-flag--mo {background-position: 0 -8450px;}
.currency-flag.currency-flag--mp {background-position: 0 -8500px;}
.currency-flag.currency-flag--mq {background-position: 0 -8550px;}
.currency-flag.currency-flag--mr {background-position: 0 -8600px;}
.currency-flag.currency-flag--ms {background-position: 0 -8650px;}
.currency-flag.currency-flag--mt {background-position: 0 -8700px;}
.currency-flag.currency-flag--mu {background-position: 0 -8750px;}
.currency-flag.currency-flag--mv {background-position: 0 -8800px;}
.currency-flag.currency-flag--mw {background-position: 0 -8850px;}
.currency-flag.currency-flag--mx {background-position: 0 -8900px;}
.currency-flag.currency-flag--my {background-position: 0 -8950px;}
.currency-flag.currency-flag--mz {background-position: 0 -9000px;}
.currency-flag.currency-flag--na {background-position: 0 -9050px;}
.currency-flag.currency-flag--nato {background-position: 0 -9100px;}
.currency-flag.currency-flag--nc {background-position: 0 -9150px;}
.currency-flag.currency-flag--ne {background-position: 0 -9200px;}
.currency-flag.currency-flag--nf {background-position: 0 -9250px;}
.currency-flag.currency-flag--ng {background-position: 0 -9300px;}
.currency-flag.currency-flag--ni {background-position: 0 -9350px;}
.currency-flag.currency-flag--nl {background-position: 0 -9400px;}
.currency-flag.currency-flag--no {background-position: 0 -9450px;}
.currency-flag.currency-flag--northern_cyprus {background-position: 0 -9500px;}
.currency-flag.currency-flag--np {background-position: 0 -9550px;}
.currency-flag.currency-flag--nr {background-position: 0 -9600px;}
.currency-flag.currency-flag--nu {background-position: 0 -9650px;}
.currency-flag.currency-flag--nz {background-position: 0 -9700px;}
.currency-flag.currency-flag--om {background-position: 0 -9750px;}
.currency-flag.currency-flag--pa {background-position: 0 -9800px;}
.currency-flag.currency-flag--pe {background-position: 0 -9850px;}
.currency-flag.currency-flag--pf {background-position: 0 -9900px;}
.currency-flag.currency-flag--pg {background-position: 0 -9950px;}
.currency-flag.currency-flag--ph {background-position: 0 -10000px;}
.currency-flag.currency-flag--pk {background-position: 0 -10050px;}
.currency-flag.currency-flag--pl {background-position: 0 -10100px;}
.currency-flag.currency-flag--pn {background-position: 0 -10150px;}
.currency-flag.currency-flag--pr {background-position: 0 -10200px;}
.currency-flag.currency-flag--ps {background-position: 0 -10250px;}
.currency-flag.currency-flag--pt-20 {background-position: 0 -10300px;}
.currency-flag.currency-flag--pt-30 {background-position: 0 -10350px;}
.currency-flag.currency-flag--pt {background-position: 0 -10400px;}
.currency-flag.currency-flag--pw {background-position: 0 -10450px;}
.currency-flag.currency-flag--py {background-position: 0 -10500px;}
.currency-flag.currency-flag--qa {background-position: 0 -10550px;}
.currency-flag.currency-flag--ro {background-position: 0 -10600px;}
.currency-flag.currency-flag--rs {background-position: 0 -10650px;}
.currency-flag.currency-flag--ru {background-position: 0 -10700px;}
.currency-flag.currency-flag--rw {background-position: 0 -10750px;}
.currency-flag.currency-flag--sa {background-position: 0 -10800px;}
.currency-flag.currency-flag--sb {background-position: 0 -10850px;}
.currency-flag.currency-flag--sc {background-position: 0 -10900px;}
.currency-flag.currency-flag--sd {background-position: 0 -10950px;}
.currency-flag.currency-flag--se {background-position: 0 -11000px;}
.currency-flag.currency-flag--sg {background-position: 0 -11050px;}
.currency-flag.currency-flag--sh {background-position: 0 -11100px;}
.currency-flag.currency-flag--si {background-position: 0 -11150px;}
.currency-flag.currency-flag--sindh {background-position: 0 -11200px;}
.currency-flag.currency-flag--sj {background-position: 0 -11250px;}
.currency-flag.currency-flag--sk {background-position: 0 -11300px;}
.currency-flag.currency-flag--sl {background-position: 0 -11350px;}
.currency-flag.currency-flag--sm {background-position: 0 -11400px;}
.currency-flag.currency-flag--sn {background-position: 0 -11450px;}
.currency-flag.currency-flag--so {background-position: 0 -11500px;}
.currency-flag.currency-flag--somaliland {background-position: 0 -11550px;}
.currency-flag.currency-flag--south_ossetia {background-position: 0 -11600px;}
.currency-flag.currency-flag--sr {background-position: 0 -11650px;}
.currency-flag.currency-flag--ss {background-position: 0 -11700px;}
.currency-flag.currency-flag--st {background-position: 0 -11750px;}
.currency-flag.currency-flag--sv {background-position: 0 -11800px;}
.currency-flag.currency-flag--sx {background-position: 0 -11850px;}
.currency-flag.currency-flag--sy {background-position: 0 -11900px;}
.currency-flag.currency-flag--sz {background-position: 0 -11950px;}
.currency-flag.currency-flag--tc {background-position: 0 -12000px;}
.currency-flag.currency-flag--td {background-position: 0 -12050px;}
.currency-flag.currency-flag--tg {background-position: 0 -12100px;}
.currency-flag.currency-flag--th {background-position: 0 -12150px;}
.currency-flag.currency-flag--tibet {background-position: 0 -12200px;}
.currency-flag.currency-flag--tj {background-position: 0 -12250px;}
.currency-flag.currency-flag--tk {background-position: 0 -12300px;}
.currency-flag.currency-flag--tl {background-position: 0 -12350px;}
.currency-flag.currency-flag--tm {background-position: 0 -12400px;}
.currency-flag.currency-flag--tn {background-position: 0 -12450px;}
.currency-flag.currency-flag--to {background-position: 0 -12500px;}
.currency-flag.currency-flag--tr {background-position: 0 -12550px;}
.currency-flag.currency-flag--transnistria {background-position: 0 -12600px;}
.currency-flag.currency-flag--tt {background-position: 0 -12650px;}
.currency-flag.currency-flag--tv {background-position: 0 -12700px;}
.currency-flag.currency-flag--tw {background-position: 0 -12750px;}
.currency-flag.currency-flag--tz {background-position: 0 -12800px;}
.currency-flag.currency-flag--ua {background-position: 0 -12850px;}
.currency-flag.currency-flag--ug {background-position: 0 -12900px;}
.currency-flag.currency-flag--united_nations {background-position: 0 -12950px;}
.currency-flag.currency-flag--us-hi {background-position: 0 -13000px;}
.currency-flag.currency-flag--us {background-position: 0 -13050px;}
.currency-flag.currency-flag--uy {background-position: 0 -13100px;}
.currency-flag.currency-flag--uz {background-position: 0 -13150px;}
.currency-flag.currency-flag--va {background-position: 0 -13200px;}
.currency-flag.currency-flag--vc {background-position: 0 -13250px;}
.currency-flag.currency-flag--ve {background-position: 0 -13300px;}
.currency-flag.currency-flag--vg {background-position: 0 -13350px;}
.currency-flag.currency-flag--vi {background-position: 0 -13400px;}
.currency-flag.currency-flag--vn {background-position: 0 -13450px;}
.currency-flag.currency-flag--vu {background-position: 0 -13500px;}
.currency-flag.currency-flag--ws {background-position: 0 -13550px;}
.currency-flag.currency-flag--xk {background-position: 0 -13600px;}
.currency-flag.currency-flag--xx {background-position: 0 -13650px;}
.currency-flag.currency-flag--ye {background-position: 0 -13700px;}
.currency-flag.currency-flag--yiddish {background-position: 0 -13750px;}
.currency-flag.currency-flag--za {background-position: 0 -13800px;}
.currency-flag.currency-flag--zm {background-position: 0 -13850px;}
.currency-flag.currency-flag--zw {background-position: 0 -13900px;}